module.exports = {
  defaultTitle: 'Juan Combetto',
  logo: 'https://www.omniwired.com/favicon/favicon-512.png',
  author: 'Juan Combetto',
  url: 'https://www.omniwired.com',
  legalName: 'OmniWired',
  defaultDescription: 'I’m Juan/OmniWired and I’m an engineer!',
  socialLinks: {
    twitter: 'http://www.twitter.com/omniwired',
    github: 'https://github.com/omniwired',
    linkedin: 'https://www.linkedin.com/in/combetto/',
  },
  googleAnalyticsID: 'UA-104269-11',
  themeColor: '#6b63ff',
  backgroundColor: '#6b63ff',
  social: {
    twitter: '@omniwired',
  },
  address: {
    city: 'City',
    region: 'Region',
    country: 'Country',
    zipCode: 'ZipCode',
  },
  contact: {
    email: 'email',
    phone: 'phone number',
  },
  foundingDate: '2018',
};
