import React, {useContext} from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { ThemeContext } from 'providers/ThemeProvider';
import ToggleTheme from 'components/theme/Header/ToggleTheme';
import { Wrapper, SocialLinks } from './styles';
import social from "../../Footer/social.json";
import {Brand} from "../Navbar/styles";
import {Link} from "gatsby";

const NavbarLinks = ({ desktop }) => {
  const { theme } = useContext(ThemeContext);

    return (
        <Wrapper desktop={desktop} theme={theme}>
            <AnchorLink href="#about">About</AnchorLink>
            <AnchorLink href="#projects">Projects</AnchorLink>
            <Brand as={Link} to="/portfolio" theme={theme}>Portfolio</Brand>
            <a href="/cv/CV_Combetto.pdf">CV</a>
            <a target href="https://www.linkedin.com/in/combetto/">Linkedin</a>
            {/*<a href="https://www.linkedin.com/in/combetto/" target="_blank" rel="noopener noreferrer"*/}
            {/*   aria-label={`follow me on Linkedin`}>*/}
            {/*    <img width="24" src="/icons/linkedin.svg" alt="Linkedin"/>*/}
            {/*</a>*/}
            <AnchorLink href="#contact">Contact</AnchorLink>
            <ToggleTheme/>
            <SocialLinks>
                {social.map(({id, name, link, icon}) => (
                    <a key={id} href={link} target="_blank" rel="noopener noreferrer"
                       aria-label={`follow me on ${name}`}>
                        <img width="24" src={icon} alt={name}/>
                    </a>
                ))}
            </SocialLinks>
        </Wrapper>
    );

};

export default NavbarLinks;
