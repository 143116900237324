import styled from 'styled-components';

export const Wrapper = styled.div`
  a {
    color: #000;
    text-decoration: none;

    @media (max-width: 960px) {
      color: ${({theme}) => (theme === 'light' ? '#000' : '#fff')};
    }
  }

  ${({desktop}) =>
          desktop
                  ? `
			align-items: center;
			display: flex;

			@media (max-width: 960px) {
					display: none;
			}

			a {
					margin-right: 1rem;

					&:last-child {
							margin-right: unset;
					}
			}
		`
                  : `
			padding: 3rem;
			display: flex;
			flex-direction: column;
			align-items: center;
			a {
					margin-bottom: 1rem;

					&:last-child {
							margin-bottom: unset;
					}
			}
	`}
`;

export const SocialLinks = styled.div`
  display: none;
  align-items: baseline;
  margin-top: 2em;
  justify-content: space-around;
  width: 12em;

  a {
    margin: 0 0.5rem;

    img {
      margin: 0;
    }

    &:first-child,
    &:last-child {
      margin: 0;
    }
  }
	@media (max-width: 960px) {
		display: flex;
	}
`;
